import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { FirstStep, SecondStep, ThirdStep, Success } from 'app/components';
import useAppState from 'app/hooks/useAppState';

// Step titles
const labels = ['Availability', 'Personal Info', 'Payment Method'];
const handleSteps = step => {
	switch (step) {
		case 0:
			return <FirstStep />;
		case 1:
			return <SecondStep />;
		case 2:
			return <ThirdStep />;
		case 3:
			return <Success />;
		default:
			throw new Error('Unknown step');
	}
};

export default function StepForm() {
	const { appState, setAppState } = useAppState();
	const { activeStep } = appState;

	return (
		<>
			<Box sx={{ mt: 2 }}>
				<Typography variant="h4" align="center" color="secondary">
					Ez Order
				</Typography>
			</Box>
			<Stepper activeStep={activeStep} sx={{ py: 5 }} alternativeLabel>
				{labels.map(label => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>

			{handleSteps(activeStep)}
		</>
	);
}
