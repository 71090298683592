import { Card, CardContent, Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
//Local
import useAppState from 'app/hooks/useAppState';
import PaymentMethodItem from './PaymentMethodItem';
import { FUEL_LABEL, FUEL_VALUES } from 'app/constants/strings';
import COLORS from 'app/constants/colors';

export default function Success() {
	const { appState } = useAppState();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12}>
				<Alert severity="success">
					<AlertTitle>Your order has been created!</AlertTitle>
					Download the app and enjoy all the conveniences that EzFill has for you! -
					<strong>
						<Link color="inherit" target="_blank" href="https://ezfl.com/download" style={{ textDecoration: 'none' }}>
							{' Check it out'}
						</Link>
					</strong>
				</Alert>
			</Grid>
			<Grid item xs={12} sm={12} style={{ minHeight: '170px' }}>
				<Card variant="outlined">
					<CardContent>
						<Typography variant="h5" component="div" style={{ color: COLORS.secondary }}>
							Order Summary
						</Typography>
						<Typography style={{ fontSize: 14, paddingTop: 10 }} color="text.secondary" gutterBottom>
							Order For
						</Typography>
						<Typography style={{ fontWeight: 400 }}>
							{appState.firstName} {appState.lastName}
						</Typography>
						<Typography style={{ fontSize: 14, paddingTop: 10 }} color="text.secondary" gutterBottom>
							Fuel Requested
						</Typography>
						<Typography style={{ fontWeight: 400 }}>{FUEL_LABEL[FUEL_VALUES.REGULAR]}</Typography>
						<Typography style={{ fontSize: 14, paddingTop: 10 }} color="text.secondary" gutterBottom>
							Payment Method
						</Typography>
						<PaymentMethodItem {...appState.paymentMethod} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
