import { Typography } from '@mui/material';
//Local
import COLORS from 'app/constants/colors';

export default function PaymentMethodItem({ last4 = '', brand = '', expMonth = '', expYear = '' }) {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				height: 50,
				borderWidth: 1,
				borderStyle: 'solid',
				borderColor: '#DDDD',
				borderRadius: 5,
				alignItems: 'center',
				marginTop: 10,
			}}>
			<img style={{ width: 38, marginLeft: 10, resizeMode: 'contain' }} src={'/assets/images/pm_brands/card.png'} alt="EzFill" />
			<div
				style={{
					textTransform: 'capitalize',
					color: '#333',
					fontSize: 14,
					marginLeft: 10,
					width: 130,
				}}>
				{brand} *{last4}
			</div>
			<div style={{ color: '#333', fontSize: 14, marginLeft: 5 }}>Exp. {`${expMonth}/${expYear}`}</div>
		</div>
	);
}
