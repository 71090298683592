export const FUEL_VALUES = {
	REGULAR: '87',
	PREMIUM: '93',
	DIESEL: 'ulsd',
};

export const FUEL_LABEL = {
	[FUEL_VALUES.REGULAR]: 'Regular',
	[FUEL_VALUES.PREMIUM]: 'Premium',
	[FUEL_VALUES.DIESEL]: 'Diesel',
};
