import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, Box, Button, Alert, AlertTitle } from '@mui/material';
//Local
import useAppState from 'app/hooks/useAppState';
import OrderService from 'app/services/OrderService';
import LoadingScreen from './LoadingScreen';

const FirstStep = () => {
	const { appState, setAppState } = useAppState();
	const [searchParams] = useSearchParams();
	const truckId = searchParams.get('id');
	const [userLocation, setUserLocation] = useState({
		loaded: false,
	});
	const [availability, setAvailability] = useState({ status: 'pending' });
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!('geolocation' in navigator)) {
			setUserLocation({
				loaded: true,
				error: 'Geolocation not supported. Please try from another device.',
			});
			return;
		}
		navigator.geolocation.getCurrentPosition(
			location => {
				setUserLocation({
					loaded: true,
					coordinates: {
						latitude: location.coords.latitude,
						longitude: location.coords.longitude,
					},
				});
			},
			error => {
				setUserLocation({
					loaded: true,
					error: 'We have detected that you are not sharing your location. Please enable location sharing to know service availability.',
				});
			},
		);
	}, []);

	useEffect(() => {
		if (!userLocation?.coordinates || !truckId) return;
		serviceAvailability();
	}, [userLocation, truckId]);

	const serviceAvailability = async () => {
		try {
			setIsLoading(true);
			const resp = await OrderService.serviceAvailability({ userLocation: userLocation.coordinates, truckId });
			const { userAddress, fuelsInSelectedLocation } = resp.data;
			setAvailability({ status: 'ok' });
			setAppState({
				...appState,
				truckId,
				userLatitude: userLocation.coordinates.latitude,
				userLongitude: userLocation.coordinates.longitude,
				fuelsInSelectedLocation,
			});
		} catch (err) {
			setAvailability({ status: 'denied', error: err.response?.data?.message || 'Network Error' });
		} finally {
			setIsLoading(false);
		}
	};

	const AvailabilityMessage = () => {
		if (!userLocation.loaded || isLoading) return <LoadingScreen />;

		if (userLocation?.error)
			return (
				<Alert severity="error">
					<AlertTitle>Share Location</AlertTitle>
					{userLocation.error}
				</Alert>
			);

		if (!truckId)
			return (
				<Alert severity="error">
					<AlertTitle>Invalid URL</AlertTitle>
					You have accessed from an invalid URL. Please scan the QR code again.
				</Alert>
			);

		if (availability.status === 'pending')
			return (
				<Alert severity="warning">
					<AlertTitle>Share Location</AlertTitle>
					Please, share your location to know service availability and refresh the page.
				</Alert>
			);

		if (availability.status === 'denied')
			return (
				<Alert severity="error">
					<AlertTitle>Service Availability</AlertTitle>
					{availability.error}
				</Alert>
			);

		if (availability.status === 'ok')
			return (
				<Alert severity="success">
					<AlertTitle>We are serving at your location</AlertTitle>
					Go ahead and get your EzFill!
				</Alert>
			);
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} style={{ minHeight: '170px' }}>
					<AvailabilityMessage />
				</Grid>
			</Grid>

			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					variant="contained"
					sx={{ mt: 3, ml: 1 }}
					disabled={!isLoading && availability.status !== 'ok'}
					color="primary"
					onClick={() => {
						setAppState({
							...appState,
							activeStep: appState.activeStep + 1,
						});
					}}>
					Next
				</Button>
			</Box>
		</>
	);
};

export default FirstStep;
