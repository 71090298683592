import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Footer() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Click '}
			<Link color="inherit" target="_blank" href="https://ezfl.com/download">
				here
			</Link>
			{' to Download App.'}
		</Typography>
	);
}

export default memo(Footer);
