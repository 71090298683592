import React, { createContext, useState } from 'react';

const defaultAppState = {
	activeStep: 0,
	truckId: undefined,
	userLatitude: undefined,
	userLongitude: undefined,
	agreenemt: undefined,
	firstName: undefined,
	lastName: undefined,
	email: undefined,
	phone: undefined,
	paymentMethod: undefined,
	fuelsInSelectedLocation: [],
};

const AppContext = createContext({
	appState: defaultAppState,
	setAppState: () => {},
});

export const AppStateProvider = ({ children }) => {
	const [appState, setAppState] = useState(defaultAppState);

	const handleBack = () => {
		setAppState({
			...appState,
			activeStep: appState.activeStep - 1,
		});
	};

	return (
		<AppContext.Provider
			value={{
				appState,
				setAppState,
				handleBack,
			}}>
			{children}
		</AppContext.Provider>
	);
};

export default AppContext;
