const { post } = require('app/api');

class OrderService {
	static async serviceAvailability(params) {
		return await post('/ez-order/availability', {
			data: params,
		});
	}
	
	static async createOrder(params) {
		return await post('/ez-order/create', {
			data: params,
		});
	}
}

export default OrderService;
