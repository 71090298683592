import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { AppStateProvider } from 'app/contexts/AppContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

//Local
import { Header, StepForm, Footer } from 'app/components';
import COLORS from 'app/constants/colors';
import './App.css';

const theme = createTheme({
	palette: {
		primary: {
			main: COLORS.primary,
		},
		secondary: {
			main: COLORS.secondary,
		},
	},
});

const stripePromise = loadStripe('pk_live_lF320M9hEoXztWrrj4GFX0t6'); //PRODUCTION
//const stripePromise = loadStripe('pk_test_51IHWE6HLpiFHJFG2CUr09nC3fisXUXWdCpLCJ7AiPRZrv5uAjpo0hqHFGmGit4fQj3ZGK3GEEXSVBIXQbWnRuePo00GoCzXYC6'); //LOCAL TEST

const App = () => {
	const stripeOptions = {
		mode: 'payment',
		amount: 50,
		currency: 'usd',
		locale: 'en',
		appearance: {},
		paymentMethodCreation: 'manual',
	};

	return (
		<AppStateProvider>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Header />
				<Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
					<Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
						<Elements stripe={stripePromise} options={stripeOptions}>
							<StepForm />
						</Elements>
					</Paper>
					<Footer />
				</Container>
			</ThemeProvider>
		</AppStateProvider>
	);
};

export default App;
