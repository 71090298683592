import axios from 'axios';

const axiosBaseOptions = config => {
	const baseURL = 'https://ezfill.app/v4-api/'; //http://192.168.0.198:8083/
	return {
		baseURL,
		...config,
	};
};

const request = config => axios.request(axiosBaseOptions(config));

const get = (url = '', config = {}) => request({ url, ...config, method: 'GET' });

const post = (url = '', config) => request({ url, ...config, method: 'POST' });

const put = (url = '', config = {}) => request({ url, ...config, method: 'PUT' });

const patch = (url = '', config = {}) => request({ url, ...config, method: 'PATCH' });

const del = (url = '', config = {}) => request({ url, ...config, method: 'DELETE' });

export { get, post, put, patch, del };
