import React, { memo } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

function Header() {
	return (
		<AppBar position="static">
			<Toolbar>
				<Link title="EzFill" rel="noopener noreferrer" target="_blank" href="https://ezfl.com/" color="inherit">
					<img width="120px" src="/assets/images/app_logomark_text_white.png" alt="EzFill" />
				</Link>
			</Toolbar>
		</AppBar>
	);
}

export default memo(Header);
